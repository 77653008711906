<template>
  <div class="page-customer-information">
    <v-row justify="space-between" align="center">
      <span class="text-wrap display-1 ma-3">{{ $t('information') }}</span>
    </v-row>
    <v-divider class="mt-2 mb-4"></v-divider>
    <v-row class="py-10">
      <v-col cols="4">
        <v-card>
          <v-card-title>
            <v-icon :color="$route.params.customerUUID === 'BCM' ? 'orange' : 'privateSupplier'" class="mr-2">
              fas fa-warehouse
            </v-icon>
            <span class="text-subtitle-2">
              {{ customer.name }}
            </span>
          </v-card-title>
          <v-card-text>
            <div class="font-weight-medium">
              {{ customer.address.company }}
            </div>
            <div v-if="customer.address.firstname">
              {{ customer.address.firstname }}
            </div>
            <div v-if="customer.address.lastname">
              {{ customer.address.lastname }}
            </div>
            <div>
              {{ customer.address.streetAddress }}
            </div>
            <div>
              {{ customer.address.zipCode }} {{ customer.address.city }}
            </div>
            <div v-if="customer.address.state">
              {{ customer.address.state }}
            </div>
            <div>
              {{ $t(`countries.${customer.address.country}`) }}
            </div>
            <div v-if="customer.address.VATnumber">
              {{ customer.address.VATnumber }}
            </div>
            <div v-if="customer.address.phoneNumber">
              {{ customer.address.phoneNumber }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <!-- Column 2 -->
        <!-- Column 2 -->
        <!-- Column 2 -->
        <!-- Column 2 -->
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <v-icon class="mr-2">
                  fas fa-address-book
                </v-icon>
                <span class="text-subtitle-2">
                  {{ $t('numberOfContact') }}
                </span>
              </v-card-title>
              <v-card-text class="text-center font-weight-bold text-h3">
                {{ customerUsers.length || 0 }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-card-title>
                <v-icon class="mr-2">
                  fas fa-cubes
                </v-icon>
                <span class="text-subtitle-2">
                  {{ $t('numberOfParts') }}
                </span>
              </v-card-title>
              <v-card-text class="text-center font-weight-bold text-h3">
                {{ customerParts.length || 0 }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- Quotes Section -->
        <!-- Quotes Section -->
        <!-- Quotes Section -->
        <!-- Quotes Section -->
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-card-title>
                <v-icon class="mr-2">
                  fas fa-file-signature
                </v-icon>
                <span class="text-subtitle-2">
                  {{ $t('numberOfQuotes') }}
                </span>
              </v-card-title>
              <v-card-text class="text-center">
                <p class="text-h4 font-weight-bold">
                  {{ $t('total') }} {{ customerQuotes.length || 0 }}
                </p>
                <p class="text-h5 font-weight-bold success--text">
                  {{ $t('win') }} {{ getQuotesByStatus('CLOSED').length }}
                </p> 
                <p class="text-h5 font-weight-bold orange--text">
                  {{ $t('lost') }} {{ getQuotesByStatus('DECLINED').length || 0 }}
                </p> 
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title>
                <v-icon class="mr-2">
                  fas fa-file-signature
                </v-icon>
                <v-icon class="mr-2">
                  fas fa-euro-sign
                </v-icon>
                <span class="text-subtitle-2">
                  {{ $t('totalAmountOfQuotes') }}
                </span>
              </v-card-title>
              <v-card-text class="text-center">
                <p class="text-h4 font-weight-bold">
                  {{ $t('total') }} {{ $n(getTotalAmountQuotesByStatus(), "currency", "fr") }}
                </p>
                <p class="text-h5 font-weight-bold success--text">
                  {{ $t('win') }} {{ $n(getTotalAmountQuotesByStatus('CLOSED'), "currency", "fr") }}
                </p>
                <p class="text-h5 font-weight-bold orange--text">
                  {{ $t('lost') }} {{ $n(getTotalAmountQuotesByStatus('DECLINED'), "currency", "fr") }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- Orders Section -->
        <!-- Orders Section -->
        <!-- Orders Section -->
        <!-- Orders Section -->
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-card-title>
                <v-icon class="mr-2">
                  fas fa-box-open
                </v-icon>
                  
                <span class="text-subtitle-2">
                  {{ $t('numberOfOrders') }}
                </span>
              </v-card-title>
              <v-card-text class="text-center">
                <p class="text-h4 font-weight-bold">
                  {{ $t('total') }} {{ customerOrders.length || 0 }}
                </p>
                <p class="text-h5 font-weight-bold success--text">
                  {{ $t('win') }} {{ getOrdersByStatus('DELIVERED').length || 0 }}
                </p> 
                <p class="text-h5 font-weight-bold orange--text">
                  {{ $t('lost') }} {{ getOrdersByStatus('CANCELED').length || 0 }}
                </p> 
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title>
                <v-icon class="mr-2">
                  fas fa-box-open
                </v-icon>
                <v-icon class="mr-2">
                  fas fa-euro-sign
                </v-icon>
                <span class="text-subtitle-2">
                  {{ $t('totalAmountOfOrders') }}
                </span>
              </v-card-title>
              <v-card-text class="text-center">
                <p class="text-h4 font-weight-bold">
                  {{ $t('total') }} {{ $n(getTotalAmountOrdersByStatus(), "currency", "fr") }}
                </p>
                <p class="text-h5 font-weight-bold success--text">
                  {{ $t('win') }} {{ $n(getTotalAmountOrdersByStatus('DELIVERED'), "currency", "fr") }}
                </p>
                <p class="text-h5 font-weight-bold orange--text">
                  {{ $t('lost') }} {{ $n(getTotalAmountOrdersByStatus('CANCELED'), "currency", "fr") }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

const i18nData = require('./pageCustomerInformation.i18n.json');

export default {
  name: 'PageCustomerInformation',
  components: {
  },
  props: {
    /**
     * Customer
     */
    customer: {
      type: Object,
      default: null
    },
    /**
     * Users
     */
    customerUsers: {
      type: Array,
      default: null
    },
    /**
     * Quotes
     */
    customerQuotes: {
      type: Array,
      default: null
    },
    /**
     * Orders
     */
    customerOrders: {
      type: Array,
      default: null
    },
    /**
     * Parts
     */
    customerParts: {
      type: Array,
      default: null
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    getQuotesByStatus(status) {
      const result = [];
      this.customerQuotes.forEach(quote =>{
        if(quote.status === status) {
          result.push(quote);
        }
      });
      return result;
    },
    getTotalAmountQuotesByStatus(status = null) {
      let amount = 0;
      let quotes = this.customerQuotes;

      if(status) {
        quotes = this.getQuotesByStatus(status);
      }
      quotes.forEach(quote => {
        amount += Number.parseFloat(quote.dispatch.purchase.totalAmount);
      });
      
      return amount.toFixed(2);
    },
    getOrdersByStatus(status) {
      const result = [];
      this.customerOrders.forEach(order =>{
        if(order.status === status) {
          result.push(order);
        }
      });
      return result;
    },
    getTotalAmountOrdersByStatus(status = null) {
      let amount = 0;
      let orders = this.customerOrders;

      if(status) {
        orders = this.getOrdersByStatus(status);
      }
      orders.forEach(order => {
        amount += Number.parseFloat(order.quote.dispatch.purchase.totalAmount);
      });
      
      return amount.toFixed(2);
    }
  },
  computed: {
  }
};
</script>
