var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-customer-information"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('information')))])]), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-row', {
    staticClass: "py-10"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": _vm.$route.params.customerUUID === 'BCM' ? 'orange' : 'privateSupplier'
    }
  }, [_vm._v(" fas fa-warehouse ")]), _c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.customer.name) + " ")])], 1), _c('v-card-text', [_c('div', {
    staticClass: "font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.customer.address.company) + " ")]), _vm.customer.address.firstname ? _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.firstname) + " ")]) : _vm._e(), _vm.customer.address.lastname ? _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.lastname) + " ")]) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.streetAddress) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.zipCode) + " " + _vm._s(_vm.customer.address.city) + " ")]), _vm.customer.address.state ? _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.state) + " ")]) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(_vm.$t("countries.".concat(_vm.customer.address.country))) + " ")]), _vm.customer.address.VATnumber ? _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.VATnumber) + " ")]) : _vm._e(), _vm.customer.address.phoneNumber ? _c('div', [_vm._v(" " + _vm._s(_vm.customer.address.phoneNumber) + " ")]) : _vm._e()])], 1)], 1), _c('v-col', [_c('v-row', [_c('v-col', [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-address-book ")]), _c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('numberOfContact')) + " ")])], 1), _c('v-card-text', {
    staticClass: "text-center font-weight-bold text-h3"
  }, [_vm._v(" " + _vm._s(_vm.customerUsers.length || 0) + " ")])], 1)], 1), _c('v-col', [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-cubes ")]), _c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('numberOfParts')) + " ")])], 1), _c('v-card-text', {
    staticClass: "text-center font-weight-bold text-h3"
  }, [_vm._v(" " + _vm._s(_vm.customerParts.length || 0) + " ")])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-file-signature ")]), _c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('numberOfQuotes')) + " ")])], 1), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('p', {
    staticClass: "text-h4 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('total')) + " " + _vm._s(_vm.customerQuotes.length || 0) + " ")]), _c('p', {
    staticClass: "text-h5 font-weight-bold success--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('win')) + " " + _vm._s(_vm.getQuotesByStatus('CLOSED').length) + " ")]), _c('p', {
    staticClass: "text-h5 font-weight-bold orange--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('lost')) + " " + _vm._s(_vm.getQuotesByStatus('DECLINED').length || 0) + " ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-file-signature ")]), _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-euro-sign ")]), _c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('totalAmountOfQuotes')) + " ")])], 1), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('p', {
    staticClass: "text-h4 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('total')) + " " + _vm._s(_vm.$n(_vm.getTotalAmountQuotesByStatus(), "currency", "fr")) + " ")]), _c('p', {
    staticClass: "text-h5 font-weight-bold success--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('win')) + " " + _vm._s(_vm.$n(_vm.getTotalAmountQuotesByStatus('CLOSED'), "currency", "fr")) + " ")]), _c('p', {
    staticClass: "text-h5 font-weight-bold orange--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('lost')) + " " + _vm._s(_vm.$n(_vm.getTotalAmountQuotesByStatus('DECLINED'), "currency", "fr")) + " ")])])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-box-open ")]), _c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('numberOfOrders')) + " ")])], 1), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('p', {
    staticClass: "text-h4 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('total')) + " " + _vm._s(_vm.customerOrders.length || 0) + " ")]), _c('p', {
    staticClass: "text-h5 font-weight-bold success--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('win')) + " " + _vm._s(_vm.getOrdersByStatus('DELIVERED').length || 0) + " ")]), _c('p', {
    staticClass: "text-h5 font-weight-bold orange--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('lost')) + " " + _vm._s(_vm.getOrdersByStatus('CANCELED').length || 0) + " ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-box-open ")]), _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-euro-sign ")]), _c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('totalAmountOfOrders')) + " ")])], 1), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('p', {
    staticClass: "text-h4 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('total')) + " " + _vm._s(_vm.$n(_vm.getTotalAmountOrdersByStatus(), "currency", "fr")) + " ")]), _c('p', {
    staticClass: "text-h5 font-weight-bold success--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('win')) + " " + _vm._s(_vm.$n(_vm.getTotalAmountOrdersByStatus('DELIVERED'), "currency", "fr")) + " ")]), _c('p', {
    staticClass: "text-h5 font-weight-bold orange--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('lost')) + " " + _vm._s(_vm.$n(_vm.getTotalAmountOrdersByStatus('CANCELED'), "currency", "fr")) + " ")])])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }